<template>
  <div>
    <Layout>
      <Column size="5">
        <div class="filter">
          <div class="filter__input">
            <div class="filter__input-icon">
              <i class="mdi mdi-magnify"></i>
            </div>
            <div class="filter__input-input">
              <input
                type="text"
                placeholder="Buscar colaborador"
                v-model="searchText"
                @keypress.enter="setQuery"
              />
            </div>
            <div class="filter__input-dropdown">
              <Dropdown>
                <span class="pw-tag" :class="dropdownOptions[optionSelected].color" slot="trigger">
                  {{ dropdownOptions[optionSelected].text }}
                  <i class="mdi mdi-chevron-down"></i>
                </span>
                <DropdownItem
                  v-for="option in Object.keys(dropdownOptions)"
                  :key="option"
                  @click="() => (optionSelected = option)"
                >
                  <span class="pw-tag" :class="dropdownOptions[option].color">
                    {{ dropdownOptions[option].text }}
                  </span>
                </DropdownItem>
              </Dropdown>
            </div>
          </div>
        </div>
      </Column>
    </Layout>
  </div>
</template>

<script>
export default {
  name: 'EmployeeFilter',
  data() {
    return {
      optionSelected: 'NameSearchQuery',
      searchText: '',
      dropdownOptions: {
        NameSearchQuery: {
          text: 'Nombre',
          color: 'is-color3',
        },
        RFCSearchQuery: {
          text: 'RFC',
          color: 'is-color5',
        },
        CURPSearchQuery: {
          text: 'CURP',
          color: 'is-color8',
        },
        EmployeeIDs: {
          text: 'ID del colaborador',
          color: 'is-color4',
        },
        EmailSearchQuery: {
          text: 'Correo electrónico',
          color: 'is-color9',
        },
      },
      query: {
        CURPSearchQuery: '',
        EmailSearchQuery: '',
        EmployeeIDs: [],
        NameSearchQuery: '',
        RFCSearchQuery: '',
      },
      arrayTypes: ['EmployeeIDs'],
    };
  },
  methods: {
    startSearch() {
      this.$emit('onSearch', this.query);
    },
    setQuery() {
      if (this.searchText === '') {
        Object.keys(this.query).forEach((x) => {
          this.query[x] = this.getKeyValue(x, this.searchText);
        });
      } else {
        Object.keys(this.query).forEach((x) => {
          if (x === this.optionSelected) {
            this.query[x] = this.getKeyValue(x, this.searchText);
          } else {
            this.query[x] = this.getKeyValue(x, '');
          }
        });
      }
      this.startSearch();
    },
    getKeyValue(key, value, split = ',') {
      if (this.arrayTypes.includes(key)) {
        return value.split(split).filter((x) => x !== '');
      }
      return value;
    },
  },
  watch: {
    searchText(val) {
      if (val === '') {
        this.setQuery();
      }
    },
  },
};
</script>

<style lang="scss">
.filter__input {
  display: flex;
  background-color: #f2f5f8;
  border: 1px solid #d5dbf3;
  padding: 0 8px;
  box-sizing: border-box;
  position: relative;
  &-icon {
    width: 16px;
    color: #89959e;
    position: relative;
    margin-right: 8px;
    justify-content: center;
    align-items: center;
    i {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &-dropdown {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-55%);
  }
  &-input {
    flex: auto;
    input {
      padding: 8px 0;
      background: transparent;
      border: none;
      outline: none;
      width: 100%;
    }
  }
}
</style>
