<template>
  <div>
    <Frame :loading="loading" title="Colaboradores" v-show="$route.name === 'employeesLookup'">
      <EmployeeFilter @onSearch="onSearch" />
      <Grid
        :actions="actions"
        :columns="columns"
        :data="data"
        :pageSize="pagination.PageSize"
        :paginatedByServer="true"
        :showAdd="false"
        :totalItems="pagination.TotalRecords"
        @changePage="changePage"
      />
    </Frame>
    <router-view></router-view>
  </div>
</template>

<script>
import EmployeeFilter from './EmployeeFilter.vue';

export default {
  name: 'Employees',
  components: {
    EmployeeFilter,
  },
  data() {
    return {
      data: [],
      loading: false,
      actions: [
        {
          text: 'Ver',
          callback: this.showEmployee,
        },
      ],
      columns: [
        {
          title: 'ID',
          field: 'idEmployee',
        },
        {
          title: 'Nombre',
          field: 'employeeName',
          template: this.employeeName,
        },

        {
          title: 'RFC',
          field: 'rfc',
        },
        {
          title: 'CURP',
          field: 'curp',
        },
        {
          title: 'Email personal',
          field: 'personalEmail',
        },
      ],
      query: {
        CURPSearchQuery: '',
        EmailSearchQuery: '',
        EmployeeIDs: [],
        NameSearchQuery: '',
        RFCSearchQuery: '',
      },
      pagination: {
        PageNumber: 1,
        PageSize: 15,
        TotalRecords: null,
        TotalPages: null,
      },
    };
  },
  mounted() {
    this.getEmployees();
  },
  methods: {
    onSearch(query) {
      this.pagination.PageNumber = 1;
      this.query = query;
      this.getEmployees();
    },
    showEmployee(item) {
      this.$router.push({
        name: 'employee',
        params: {
          id: item.idEmployee,
        },
      });
    },
    employeeName(item) {
      return `${item.employeeName} ${item.employeeLastName} ${item.employeeSecondLastName}`;
    },
    getEmployees() {
      this.loading = true;
      const query = [
        `PageNumber=${this.pagination.PageNumber}`,
        `PageSize=${this.pagination.PageSize}`,
      ].join('&');
      this.$post(`employee?${query}`, this.query, 'stamp').then(({ data, headers }) => {
        const pagination = headers['x-pagination'];
        if (data.length !== 0) {
          this.pagination = JSON.parse(pagination);
        }
        this.data = data;
        this.loading = false;
      });
    },
    changePage(page) {
      this.pagination.PageNumber = page;
      this.getEmployees();
    },
  },
};
</script>
